import { render, staticRenderFns } from "./va.vue?vue&type=template&id=1f536806&scoped=true&"
import script from "./va.vue?vue&type=script&lang=js&"
export * from "./va.vue?vue&type=script&lang=js&"
import style0 from "./va.vue?vue&type=style&index=0&id=1f536806&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f536806",
  null
  
)

export default component.exports