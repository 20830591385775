<template>
  <div class="row mt-4 virtual-account">
    <div class="col-6">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="bg-info p-3">
            <h5 class="card-title mb-0 font-weight-bold">
              Virtual Account Data
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <b-form-group id="input-email" label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    placeholder="example@gmail.com"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group
                  id="input-number"
                  label="Virtual Account Number"
                  label-for="number"
                  description="Virtual Account Number is obtained from transfer receipt."
                >
                  <b-form-input
                    id="number"
                    v-model="form.number"
                    type="text"
                    placeholder="Enter number"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-button class="mr-3 text-white" type="submit" variant="info"
                  >Search Data</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "virtual-account",
  data() {
    return {
      form: {
        email: "",
        number: ""
      },
      show: true
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.number = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style scoped>
.virtual-account {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
